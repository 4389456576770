import { useState } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import Image from 'next/image'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { COLOR_THEME } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import ArrowLeft from 'src/components/icons/ArrowLeft'
import ArrowRight from 'src/components/icons/ArrowRight'
import styles from 'src/components/quote/Quote.module.css'
import theme, { COLOR, SECONDARY_COLOR } from 'src/styles/theme'
import FloatingActionButton from 'src/common/components/floating-action-button/FloatingActionButton'
import sanitize from 'src/common/utils/js/sanitize'

const ANIMATION_DIRECTION = {
  left: 'slideToLeft',
  right: 'slideToRight',
}

const useStyles = makeStyles()((defaultTheme, props) => {
  const {
    backgroundColor,
    graphicPosition,
    isCarousel,
    isCallout,
    quoteContainsImage,
    carouselContainsNoImage,
  } = props

  let background
  switch (backgroundColor) {
    case COLOR_THEME.THE_BAKER_BEACH:
      background = COLOR.BAKER_BEACH_WHITE
      break
    case COLOR_THEME.CRISSY_FIELD:
      // eslint-disable-next-line prefer-destructuring
      background = SECONDARY_COLOR.LIGHT[40]
      break
    default:
      background = theme.palette.presidio.color.LIGHT_BACKGROUND
      break
  }

  const authorNameCallout = { ...theme.typography.h4 }
  const authorNameSimple = { ...theme.typography.body.bold }
  const authorNameFinal = isCallout ? authorNameCallout : authorNameSimple

  const authorTitleCallout = { ...theme.typography.largeBody.default }
  const authorTitleSimple = {
    ...theme.typography.body.default,
    lineHeight: rem(23.4),
  }
  const authorTitleFinal = isCallout ? authorTitleCallout : authorTitleSimple

  const quoteTextCallout = { ...theme.typography.quote, lineHeight: rem(35) }
  const quoteTextSimple = {
    ...theme.typography.largeBody.default,
    letterSpacing: 0,
    lineHeight: rem(23),
  }
  const quoteTextFinal = isCallout ? quoteTextCallout : quoteTextSimple

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      background: background,
      position: 'relative',
      zIndex: 0,
      justifyContent: 'center',
      alignItems: 'center',
      padding: `${rem('40')} ${rem('24')}`,
      gap: quoteContainsImage ? rem('40') : 'unset',
      [theme.breakpoints.up('md')]: {
        padding: `${rem('64')} ${rem('40')}`,
      },
      [theme.breakpoints.up('lg')]: {
        alignItems: 'unset',
        flexDirection: 'row',
        gap: rem('24'),
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem('64')} ${rem('156')}`,
        gap: rem('120'),
      },
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: rem('327'),
      order: 1,
      [theme.breakpoints.up('md')]: {
        width: rem('641'),
        minHeight: quoteContainsImage ? rem('472') : 'unset',
      },
      [theme.breakpoints.up('lg')]: {
        width: rem('381'),
        minHeight: carouselContainsNoImage ? rem('202') : rem('332'),
        order: graphicPosition === 'left' ? 0 : 1,
      },
      [theme.breakpoints.up('xl')]: {
        width: '100%',
        minHeight: carouselContainsNoImage ? rem('261') : rem('369'),
      },
    },
    carouselImages: {
      height: rem('226'),
      width: '100%',
      position: 'relative',
      marginBottom: rem('8'),

      [theme.breakpoints.up('md')]: {
        height: rem('443.63'),
        marginBottom: rem('10'),
      },
      [theme.breakpoints.up('lg')]: {
        height: rem('286'),
      },
      [theme.breakpoints.up('xl')]: {
        height: rem('340'),
      },
    },
    imageCaption: {
      ...theme.typography.smallBody.default,
      color: COLOR.DARK_GRAY,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: isCarousel ? rem(442) : 'unset',
      [theme.breakpoints.up('md')]: {
        height: isCarousel ? rem(264) : 'unset',
      },
      [theme.breakpoints.up('lg')]: {
        height: isCarousel ? rem(286) : 'unset',
      },
      [theme.breakpoints.up('xl')]: {
        height: isCarousel ? rem(340) : 'unset',
      },
    },
    contentBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: isCarousel ? 'flex-start' : 'center',
      flexGrow: 1,
    },
    quoteBox: {
      marginBottom: rem('16'),
      display: 'flex',
      '&::before': {
        ...quoteTextFinal,
        content: '"“"' /* quote mark character */,
        marginLeft: rem(-8),
        color: theme.palette.primary.dark,
      },
      [theme.breakpoints.up('md')]: {
        width: rem('640'),
      },
      [theme.breakpoints.up('lg')]: {
        width: rem('539'),
        minHeight: 'unset',
      },
      [theme.breakpoints.up('xl')]: {
        width: rem('553'),
        marginBottom: rem('24'),
      },
    },
    quoteText: {
      ...quoteTextFinal,
      color: theme.palette.primary.dark,
    },
    authorBoxContainer: {
      minHeight: rem(47),
    },
    authorBox: {
      borderLeft: `solid ${theme.palette.primary.light} ${rem('2')}`,
      paddingLeft: rem('8'),
      [theme.breakpoints.up('lg')]: {
        width: rem('539'),
      },
    },
    authorName: {
      ...authorNameFinal,
      color: theme.palette.primary.dark,
      fontSize: isCallout ? rem('18') : rem('18'),
      lineHeight: isCallout ? rem('20') : rem('23'),
      fontWeight: isCallout ? 500 : 700,
      marginBottom: rem(3.21),
      letterSpacing: 'normal',
      [theme.breakpoints.up('md')]: {
        fontSize: isCallout ? rem('18') : rem('18'),
        lineHeight: isCallout ? rem('20') : rem('23'),
        fontWeight: isCallout ? 500 : 700,
        marginBottom: rem(3.6),
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: isCallout ? rem('24') : rem('18'),
        lineHeight: isCallout ? rem('22') : rem('23'),
      },
    },
    authorTitle: {
      ...authorTitleFinal,
      letterSpacing: 'normal',
      display: 'block',
      color: COLOR.DARK_GRAY,
    },
    carouselButtons: {
      display: 'flex',
      flexDirection: 'row',
      gap: rem('16'),
    },
    backgroundImageLayer: {
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        top: 0,
        [graphicPosition === 'left' ? 'left' : 'right']: 0,
        zIndex: -1,
        height: '100%',
        width: '40%',
      },
    },
  }
})

export default function Quote(props) {
  const { data } = props
  let touchstartX
  let touchendX
  let touchstartY
  let touchendY

  // guards
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { quote, section_id } = data

  const {
    quotes,
    image_position: graphicPosition,
    text_style,
    background_pattern: forestGraphic,
    background_color: backgroundColor,
  } = quote || {}

  if (!quotes || quotes.length === 0 || !quotes[0].quote) return null

  const isCarousel = quotes.length > 1

  const [activeIndex, setActiveIndex] = useState(0)

  const [animationClassString, setAnimationClassString] = useState('')

  const activeItem = quotes[activeIndex]

  const handlePrevClick = () => {
    setActiveIndex(activeIndex === 0 ? quotes.length - 1 : activeIndex - 1)
  }

  const handleNextClick = () => {
    setActiveIndex(activeIndex === quotes.length - 1 ? 0 : activeIndex + 1)
  }

  const isCallout = text_style === 'callout'

  const quoteContainsImage = !!activeItem?.image?.url

  const carouselContainsNoImage =
    quotes.length ===
    quotes.filter((quoteItem) => !(quoteItem.image && quoteItem.image.url))
      .length

  const handleClick = (isDisabled, direction, clickHandler) => {
    if (!isDisabled) {
      setAnimationClassString(direction)
      setTimeout(() => {
        clickHandler()
      }, 500)
      setTimeout(() => setAnimationClassString(''), 1000)
    }
  }

  const handleKeyDown = (event, isDisabled, direction, clickHandler) => {
    if (!isDisabled && (event.key === 'Enter' || event.key === 'Return')) {
      handleClick(isDisabled, direction, clickHandler)
    }
  }

  function handleGesture() {
    if (isCarousel) {
      if (
        touchendX < touchstartX &&
        touchstartX - touchendX > 80 &&
        Math.abs(touchstartY - touchendY) < 20
      ) {
        handleClick(
          activeIndex >= quotes.length - 1,
          ANIMATION_DIRECTION.left,
          handleNextClick
        )
      }
      if (
        touchendX > touchstartX &&
        touchendX - touchstartX > 80 &&
        Math.abs(touchendY - touchstartY) < 20
      ) {
        handleClick(
          activeIndex <= 0,
          ANIMATION_DIRECTION.right,
          handlePrevClick
        )
      }
    }
  }

  const { classes } = useStyles({
    backgroundColor,
    graphicPosition,
    isCarousel,
    isCallout,
    quoteContainsImage,
    carouselContainsNoImage,
  })

  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))

  const bgImageSrc =
    graphicPosition === 'left'
      ? '/assets/patterns/forest/CrissyField_CropLeft.png'
      : '/assets/patterns/forest/CrissyField_CropRight.png'

  return (
    <Box
      className={`module ${classes.container}`}
      id={section_id}
      data-id="section"
      data-modulename="quote"
    >
      {lgUp && forestGraphic && (
        <Box className={classes.backgroundImageLayer}>
          <Image
            src={bgImageSrc}
            layout="fill"
            alt="background pattern"
            title="Background pattern image"
            style={{
              opacity: backgroundColor === COLOR_THEME.CRISSY_FIELD ? 1 : 0.6,
            }}
          />
        </Box>
      )}
      <Box
        onTouchStart={(e) => {
          touchstartX = e.changedTouches[0].screenX
          touchstartY = e.changedTouches[0].screenY
        }}
        onTouchEnd={(e) => {
          touchendX = e.changedTouches[0].screenX
          touchendY = e.changedTouches[0].screenY
          handleGesture()
        }}
        className={`${classes.imageContainer} ${styles[animationClassString]}`}
        data-ga-location="quotemodule_image"
      >
        {quoteContainsImage && (
          <>
            <Box className={classes.carouselImages}>
              <Image
                src={activeItem.image.url}
                objectFit="cover"
                layout="fill"
                alt={activeItem.image.alt || 'Quote image'}
                title={activeItem?.image?.title}
              />
            </Box>
            {activeItem.image.caption && (
              <Typography
                component="div"
                variant="smallBody"
                className={classes.imageCaption}
              >
                {activeItem.image.caption}
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box className={classes.contentContainer}>
        <Box
          onTouchStart={(e) => {
            touchstartX = e.changedTouches[0].screenX
            touchstartY = e.changedTouches[0].screenY
          }}
          onTouchEnd={(e) => {
            touchendX = e.changedTouches[0].screenX
            touchendY = e.changedTouches[0].screenY
            handleGesture()
          }}
          className={`${classes.contentBox} ${styles[animationClassString]}`}
        >
          <Box className={classes.quoteBox}>
            <Typography className={classes.quoteText}>
              {sanitize(`${activeItem.quote}\u201d`)}
            </Typography>
          </Box>
          <Box className={classes.authorBoxContainer}>
            <Box className={classes.authorBox}>
              <Typography
                variant={isCallout ? 'h4' : undefined}
                component="p"
                className={classes.authorName}
              >
                {activeItem.quote_attribution.name}
              </Typography>
              <Typography variant="largeBody" className={classes.authorTitle}>
                {activeItem.quote_attribution.title}
              </Typography>
            </Box>
          </Box>
        </Box>
        {isCarousel && (
          <Box className={classes.carouselButtons}>
            <Box
              tabIndex={activeIndex === 0 ? -1 : 0}
              onClick={() =>
                handleClick(
                  activeIndex <= 0,
                  ANIMATION_DIRECTION.right,
                  handlePrevClick
                )
              }
              onKeyDown={(event) =>
                handleKeyDown(
                  event,
                  activeIndex <= 0,
                  ANIMATION_DIRECTION.right,
                  handlePrevClick
                )
              }
            >
              <FloatingActionButton
                title="Previous Quote"
                aria-label="Previous Quote"
                disabled={activeIndex === 0}
                data-testid="prev-button"
                tabIndex={-1}
                data-ga-location="quotemodule_left"
              >
                <ArrowLeft gaTag="quotemodule_left" />
              </FloatingActionButton>
            </Box>
            <Box
              tabIndex={activeIndex === quotes.length - 1 ? -1 : 0}
              onClick={() =>
                handleClick(
                  activeIndex >= quotes.length - 1,
                  ANIMATION_DIRECTION.left,
                  handleNextClick
                )
              }
              onKeyDown={(event) =>
                handleKeyDown(
                  event,
                  activeIndex >= quotes.length - 1,
                  ANIMATION_DIRECTION.left,
                  handleNextClick
                )
              }
            >
              <FloatingActionButton
                title="Next Quote"
                aria-label="Next Quote"
                disabled={activeIndex === quotes.length - 1}
                data-testid="next-button"
                tabIndex={-1}
                data-ga-location="quotemodule_right"
              >
                <ArrowRight gaTag="quotemodule_right" />
              </FloatingActionButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const quotePropTypes = PropTypes.shape({
  quote: PropTypes.string.isRequired,
  quote_attribution: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
  image: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    alt: PropTypes.string,
    caption: PropTypes.string,
  }),
})

Quote.propTypes = {
  data: PropTypes.shape({
    quotes: PropTypes.arrayOf(quotePropTypes),
    image_position: PropTypes.oneOf(['left', 'right']),
    text_style: PropTypes.oneOf(['simple', 'callout']),
    background_color: PropTypes.string,
    background_pattern: PropTypes.bool,
  }),
}
